<template>
  <div style="height: 100%" class="put_center">
    <v-alert type="error" :value="!!error" border="left">
      {{ error }}
    </v-alert>
    <v-carousel
      auto
      :continuous="false"
      :cycle="false"
      :show-arrows="true"
      show-arrows-on-hover
      :hide-delimiters="mdUp ? false : true"
      delimiter-icon="mdi-minus"
      :height="mdUp ? '900' : '800'"
      ref="carousel"
    >
      <div v-for="(recipe, i) in allRecipes" :key="i">
        <v-carousel-item v-if="recipe.name == 'cover'">
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="5">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" align="end">
                    <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle-1'"
                        :style="{
                          color: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        {{ upperFirst(plan?.name) }}
                      </div>
                      <div class="text-caption">
                        A Recipe eBook by {{ user?.firstName }}
                      </div>
                    </v-col>
                    <v-col cols="4" lg="2" xl="2">
                      <v-img
                        :src="user?.customLogoUrl"
                        contain
                        height="70px"
                        width="110px"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              :color="
                                user?.brandColor ? user.brandColor : '#97c81f'
                              "
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <div class="mt-3 px-8">
                    <v-divider />
                  </div>
                  <div
                    v-show="plan?.requestStatus == 'approved'"
                    class="px-5 py-3 stamp-container"
                    style="position: relative"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2FDIETITIAN%20APPROVED%20(TRANSPARENT).png?alt=media&token=0e14d93b-7320-4683-a408-8015e9b3d346"
                      alt="Approval Seal"
                      class="stamp-seal"
                      width="200"
                      height="200"
                    />
                  </div>
                  <v-row justify="center" class="py-3 mt-2">
                    <div>
                      <v-img
                        :src="user?.customLogoUrl"
                        width="300px"
                        height="300"
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              :color="
                                user?.brandColor ? user.brandColor : '#97c81f'
                              "
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </v-row>
                  <div
                    class="py-3"
                    style="overflow: hidden auto"
                    :style="{ height: mdUp ? '285px' : '250px' }"
                  >
                    <v-row class="mt-5 px-10">
                      <v-col cols="12">
                        <div class="ql-editor" v-html="plan?.description"></div>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor
                        ? user?.brandColor
                        : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div
                    class="social-medias d-flex justify-end"
                    style="gap: 0.5rem"
                  >
                    <a
                      v-if="user.facebook"
                      :href="user.facebook"
                      target="_blank"
                    >
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a
                      v-if="user.instagram"
                      :href="user.instagram"
                      target="_blank"
                    >
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div
                    v-if="user?.isStudent"
                    class="text-body2 red--text sample-text"
                  >
                    *This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item v-else-if="recipe.name == 'instruction'">
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="5">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" align="end">
                    <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                        :style="{
                          color: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        Instructions
                      </div>
                      <div class="text-caption">
                        A Meal Plan eBook by {{ user?.firstName }}
                      </div>
                    </v-col>
                    <v-col cols="4" lg="2" xl="2">
                      <v-img
                        :src="user?.customLogoUrl"
                        contain
                        height="70px"
                        width="110px"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              :color="
                                user?.brandColor ? user.brandColor : '#97c81f'
                              "
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <div class="mt-3 px-8">
                    <v-divider />
                  </div>
                  <div
                    class="py-3 mt-3"
                    style="overflow: hidden auto"
                    :style="{ height: mdUp ? '670px' : '580px' }"
                  >
                    <v-row class="px-6" justify="center">
                      <div>
                        <div
                          class="mt-6 text-header-6 text-center font-weight-bold"
                        >
                          How to use this Ebook
                        </div>
                      </div>
                      <div>
                        <ol>
                          <li>
                            Swipe across the screen or use the arrows to
                            navigate to the recipe index.
                          </li>
                          <li>
                            On the recipe index page, you will find a list of
                            recipes suited to your requirements. Tap on the
                            recipe you wish to view.
                          </li>
                          <li>
                            On the recipe page, you can click 'VIEW RECIPE CARD'
                            for an in-depth view of the recipe ingredients and
                            method for preparation. You can also download a PDF
                            of the recipe to store for later use if you choose.
                          </li>
                          <li>
                            If you like the recipe and would like to add it to a
                            shopping list, simply click 'ADD TO SHOPPING LIST'.
                            This will add the recipe to an editable shopping
                            list.
                          </li>
                          <li>
                            Once you have edited the shopping list (if
                            required), simply click the 'DOWNLOAD LIST' button
                            to download a PDF of the categorised shopping list.
                          </li>
                          <li>
                            You can navigate back to the recipe index at any
                            time by clicking on the 'TABLE OF CONTENTS' button
                            on any recipe page.
                          </li>
                        </ol>
                      </div>
                      <div>
                        <div
                          class="mt-6 text-header-6 text-center font-weight-bold"
                        >
                          ENJOY!
                        </div>
                      </div>
                    </v-row>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor
                        ? user?.brandColor
                        : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div
                    class="social-medias d-flex justify-end"
                    style="gap: 0.5rem"
                  >
                    <a
                      v-if="user.facebook"
                      :href="user.facebook"
                      target="_blank"
                    >
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a
                      v-if="user.instagram"
                      :href="user.instagram"
                      target="_blank"
                    >
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div
                    v-if="user?.isStudent"
                    class="text-body2 red--text sample-text"
                  >
                    *This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item v-else-if="recipe.name == 'table'">
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="5">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" align="end">
                    <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                        :style="{
                          color: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        Table of Contents
                      </div>
                      <div class="text-caption">
                        A Recipe eBook by {{ user?.firstName }}
                      </div>
                    </v-col>
                    <v-col cols="4" lg="2" xl="2">
                      <v-img
                        :src="user?.customLogoUrl"
                        contain
                        height="70px"
                        width="110px"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              :color="
                                user?.brandColor ? user.brandColor : '#97c81f'
                              "
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <div
                    class="py-3"
                    style="overflow: hidden auto"
                    :style="{ height: mdUp ? '670px' : '580px' }"
                  >
                    <v-row
                      justify="center"
                      align="center"
                      v-for="(header, i) in headers"
                      :key="i"
                    >
                      <v-col cols="12">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div class="d-flex justify-space-between flex-wrap">
                              <div
                                class="text-capitalize text-body-1 grey--text"
                              >
                                {{ header }}
                              </div>
                              <div v-if="header == 'breakfast'">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      x-small
                                      color="white"
                                      class="black--text"
                                      depressed
                                      v-if="
                                        shoppingListIds.length < recipes.length
                                      "
                                      @click="startDownloadRecipes(0)"
                                    >
                                      Recipes
                                      <v-icon small
                                        >mdi-download-multiple</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span
                                    >Download a zip file of the recipes</span
                                  >
                                </v-tooltip>
                                <v-btn
                                  x-small
                                  color="white"
                                  class="black--text"
                                  depressed
                                  v-if="shoppingListIds.length < recipes.length"
                                  @click="addAllToShoppingList()"
                                >
                                  Add All Recipes
                                  <v-icon small>mdi-cart-variant</v-icon>
                                </v-btn>
                                <v-btn
                                  x-small
                                  color="white"
                                  class="black--text"
                                  depressed
                                  v-else
                                  @click="removeAllFromShoppingList()"
                                >
                                  Remove All Recipes
                                  <v-icon small>mdi-cart-off</v-icon>
                                </v-btn>
                              </div>
                            </div>
                            <v-divider />
                          </v-col>
                        </v-row>
                        <!-- <v-row class="px-2">
                          <v-col cols="12">
                            <div class=""
                            >
                              {{ header }}
                            </div>
                          </v-col>
                        </v-row> -->
                        <v-row
                          v-for="recipe in groupedRecipes[header]"
                          :key="recipe.id"
                          align="center"
                        >
                          <v-col cols="8" class="pl-8">
                            <div
                              class="d-flex flex-start align-center"
                              style="gap: 0.5rem"
                            >
                              <img
                                :src="getRecipeImg(recipe)"
                                contain
                                width="70"
                                height="70"
                                style="object-fit: cover"
                              />
                              <div
                                class="text-subtitle-2 table-content-item"
                                @click="goTo(getIndexNo(recipe.id) + 1)"
                                :style="{ color: user.brandColor }"
                              >
                                {{ recipe.name }}
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="3" class="pa-0 pr-2">
                            <div class="text-subtitle-2 text-end">
                              {{ getIndexNo(recipe.id) + 3 }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row justify="center" v-if="smDown">
                      <div class="text-body-1 grey--text my-3">
                        ------END------
                      </div>
                    </v-row>
                  </div>
                  <div
                    class="shoppingcart"
                    v-if="shoppingListIds.length"
                    :style="mdUp ? 'bottom: 40px' : 'top:130px'"
                  >
                    <v-badge
                      bordered
                      color="red darken-1"
                      :content="shoppingListIds.length"
                      :value="shoppingListIds.length"
                      overlap
                    >
                      <v-btn
                        color="grey"
                        icon
                        small
                        @click="showShoppingListDialog"
                      >
                        <v-icon>mdi-cart</v-icon>
                      </v-btn>
                    </v-badge>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor
                        ? user?.brandColor
                        : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div
                    class="social-medias d-flex justify-end"
                    style="gap: 0.5rem"
                  >
                    <a
                      v-if="user.facebook"
                      :href="user.facebook"
                      target="_blank"
                    >
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a
                      v-if="user.instagram"
                      :href="user.instagram"
                      target="_blank"
                    >
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div
                    v-if="user?.isStudent"
                    class="text-body2 red--text sample-text"
                  >
                    *This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item v-else>
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0 pt-2'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="6">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" :align="mdUp ? 'end' : 'start'">
                    <v-col
                      lg="9"
                      xl="9"
                      cols="7"
                      class="mr-3"
                      :class="mdUp ? 'text-end' : 'text-center'"
                    >
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle-1'"
                        :style="{
                          color: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        {{ upperFirst(recipe.name) }}
                      </div>
                      <div class="text-body-2">
                        <v-icon
                          v-if="
                            recipe?.mealTime[0].toLowerCase() == 'breakfast'
                          "
                          color="brown"
                          small
                          >mdi-coffee</v-icon
                        >
                        <v-icon
                          v-else-if="
                            recipe?.mealTime[0].toLowerCase() == 'lunch'
                          "
                          color="yellow darken-3"
                          small
                          >mdi-food</v-icon
                        >
                        <v-icon
                          v-else-if="
                            recipe?.mealTime[0].toLowerCase() == 'dinner'
                          "
                          color="brown"
                          small
                          >mdi-food-turkey</v-icon
                        >
                        <v-icon
                          v-else-if="
                            recipe?.mealTime[0].toLowerCase() == 'desert'
                          "
                          color="brown"
                          small
                          >mdi-food-hamburger</v-icon
                        >
                        <v-icon v-else color="green darken-3" x-small
                          >mdi-food-apple</v-icon
                        >
                        {{ upperFirst(recipe.meal.time) }}
                      </div>
                      <div class="mt-2" v-if="mdUp">
                        <v-row
                          justify="space-between"
                          align="center"
                          class="py-2"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                text
                                :color="user.brandColor"
                                v-on="on"
                                x-small
                                @click="goBack(i)"
                              >
                                <v-icon small>mdi-format-list-bulleted</v-icon>
                                <span>Table of Contents</span>
                              </v-btn>
                            </template>
                            <span>Back to Table of Contents</span>
                          </v-tooltip>
                          <div>
                            <v-btn
                              small
                              :color="user.brandColor"
                              depressed
                              class="white--text mr-2"
                              @click="setRecipe(recipe)"
                              >View Recipe Card</v-btn
                            >
                            <v-btn
                              v-if="!shoppingListIds.includes(recipe.id)"
                              small
                              :color="user.brandColor"
                              depressed
                              class="white--text"
                              @click="addToShoppingList(recipe)"
                              >Add to Shopping list</v-btn
                            >
                            <v-btn
                              v-if="shoppingListIds.includes(recipe.id)"
                              small
                              color="warning"
                              depressed
                              class="white--text"
                              @click="removeFromShoppingList(recipe.id)"
                              >Remove from Shopping list</v-btn
                            >
                          </div>
                        </v-row>
                      </div>
                      <div class="mt-2" v-else>
                        <v-btn
                          x-small
                          :color="user.brandColor"
                          depressed
                          class="white--text mr-2"
                          @click="setRecipe(recipe)"
                          >View Recipe Card</v-btn
                        >
                        <v-btn
                          v-if="!shoppingListIds.includes(recipe.id)"
                          x-small
                          :color="user.brandColor"
                          depressed
                          class="white--text"
                          @click="addToShoppingList(recipe)"
                          >Add to Shopping list</v-btn
                        >
                        <v-btn
                          v-if="shoppingListIds.includes(recipe.id)"
                          x-small
                          color="warning"
                          depressed
                          class="white--text"
                          @click="removeFromShoppingList(recipe.id)"
                          >Remove from Shopping list</v-btn
                        >
                      </div>
                    </v-col>
                    <v-col lg="2" xl="2" cols="4">
                      <v-img :src="user?.customLogoUrl" contain>
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              :color="
                                user?.brandColor ? user.brandColor : '#97c81f'
                              "
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="smDown"
                    justify="center"
                    align="center"
                    class="mt-3 mb-0"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          text
                          :color="user.brandColor"
                          v-on="on"
                          x-small
                          @click="goBack(i + 1)"
                        >
                          <v-icon small>mdi-format-list-bulleted</v-icon>
                          <span>Table of Contents</span>
                        </v-btn>
                      </template>
                      <span>Back to Table of Contents</span>
                    </v-tooltip>
                  </v-row>
                  <div class="mb-3 px-8" :class="mdUp ? 'mt-3' : 'mt-1'">
                    <v-divider />
                  </div>
                  <v-row justify="center" class="py-3">
                    <v-col lg="4" xl="4" cols="12">
                      <v-img
                        v-if="recipe.mediumPhotoUrl"
                        :src="recipe.mediumPhotoUrl"
                        height="250px"
                        :width="mdUp ? '250px' : '100%'"
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              :color="
                                user?.brandColor ? user.brandColor : '#97c81f'
                              "
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-img
                        v-else
                        :src="recipe.OriginalPhotoUrl"
                        width="250px"
                        height="250px"
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              :color="
                                user?.brandColor ? user.brandColor : '#97c81f'
                              "
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col lg="7" xl="7" cols="12">
                      <div
                        style="overflow: hidden auto"
                        :style="
                          mdUp
                            ? 'height:550px; max-height:550px;'
                            : 'height:300px;max-height:300px'
                        "
                      >
                        <v-card
                          v-if="recipe.ingredients && recipe.ingredients.length"
                          class="mb-5"
                          flat
                        >
                          <v-simple-table class="grey lighten-4" bordered dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th
                                    class="mb-5 subtitle-1 font-weight-bold"
                                    :style="{
                                      color: user?.brandColor
                                        ? user?.brandColor
                                        : '#97c81f',
                                    }"
                                  >
                                    Ingredients
                                  </th>
                                  <th>Serve Amount</th>
                                  <th>Serve Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="recipeIngredient in orderBy(
                                    recipe.ingredients,
                                    'order',
                                    'asc'
                                  )"
                                  :key="recipeIngredient.id"
                                >
                                  <td class="text-capitalize py-2" width="40%">
                                    {{ recipeIngredient.food }}
                                  </td>
                                  <td v-if="recipe.lock_serve" width="30%">
                                    {{ parseFloat(recipeIngredient.quantity) }}
                                  </td>
                                  <td v-else width="30%">
                                    {{
                                      parseFloat(
                                        (
                                          recipeIngredient.quantity *
                                          ((serves || recipe.numberOfServes) /
                                            recipe.numberOfServes)
                                        ).toFixed(2)
                                      )
                                    }}
                                  </td>
                                  <td width="30%">
                                    {{ recipeIngredient.portion }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                  <div
                    class="shoppingcart"
                    v-if="shoppingListIds.length"
                    :style="mdUp ? 'bottom: 50px' : 'top:130px'"
                  >
                    <v-badge
                      bordered
                      color="red darken-1"
                      :content="shoppingListIds.length"
                      :value="shoppingListIds.length"
                      overlap
                    >
                      <v-btn
                        color="grey"
                        icon
                        small
                        @click="showShoppingListDialog"
                      >
                        <v-icon>mdi-cart</v-icon>
                      </v-btn>
                    </v-badge>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor
                        ? user?.brandColor
                        : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div
                    class="social-medias d-flex justify-end"
                    style="gap: 0.5rem"
                  >
                    <a
                      v-if="user.facebook"
                      :href="user.facebook"
                      target="_blank"
                    >
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a
                      v-if="user.instagram"
                      :href="user.instagram"
                      target="_blank"
                    >
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div
                    v-if="user?.isStudent"
                    class="text-body2 red--text sample-text"
                  >
                    *This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </div>
    </v-carousel>
    <v-dialog
      class="overflow-visible"
      no-click-animation
      max-width="1000"
      :value="show"
      persistent
      scrollable
    >
      <close-button @click="show = false" overflow />

      <v-card v-if="recipe">
        <v-card-title :class="$classes.cardTitle">
          <span class="font-weight-bold headline">{{ recipe.name }}</span>

          <v-spacer></v-spacer>
          <v-btn
            @click="startDownload()"
            :loading="downloading"
            :color="user?.brandColor"
            class="mr-2 white--text"
            depressed
          >
            <v-icon left>mdi-printer</v-icon>
            PDF
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5 pb-7">
          <v-row>
            <v-col cols="12" md="7" lg="7" xl="7">
              <!-- INGREDIENTS -->

              <v-card
                v-if="recipe.ingredients && recipe.ingredients.length"
                class="mb-5"
                flat
              >
                <v-simple-table class="grey lighten-4" bordered dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="mb-5 subtitle-1 font-weight-bold"
                          :style="{
                            color: user?.brandColor
                              ? user?.brandColor
                              : '#97c81f',
                          }"
                        >
                          Ingredients
                        </th>
                        <th>Serve Amount</th>
                        <th>Serve Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="recipeIngredient in orderBy(
                          recipe.ingredients,
                          'order',
                          'asc'
                        )"
                        :key="recipeIngredient.id"
                      >
                        <td class="text-capitalize py-2" width="40%">
                          {{ recipeIngredient.food }}
                        </td>
                        <td v-if="recipe.lock_serve" width="30%">
                          {{ parseFloat(recipeIngredient.quantity) }}
                        </td>
                        <td width="30%">
                          {{
                            parseFloat(
                              (
                                recipeIngredient.quantity *
                                ((serves || recipe.numberOfServes) /
                                  recipe.numberOfServes)
                              ).toFixed(2)
                            )
                          }}
                        </td>
                        <td width="30%">{{ recipeIngredient.portion }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>

              <span v-if="recipe.lock_serve">
                <v-alert dense text type="warning">
                  <strong>WARNING</strong>: Original serve quantities shown only
                  due to ingredients that don't divide well
                </v-alert>
                <p class="mb-5 font-weight-bold">
                  Makes {{ recipe.numberOfServes }} Serves ({{
                    recipe.servingValues.weight | numberFormat
                  }}g per serve)
                </p>
              </span>
              <p v-else-if="recipe.servingValues" class="mb-5 font-weight-bold">
                Makes {{ serves || recipe.numberOfServes }} Serves ({{
                  recipe.servingValues.weight | numberFormat
                }}g per serve)
              </p>

              <!-- MEAL TIME -->
              <div
                v-if="recipe.mealTime && recipe.mealTime.length"
                class="mb-5"
              >
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Meal Time
                </h5>

                <p>{{ recipe.mealTime.join(', ') }}</p>
              </div>

              <!-- VIDEO -->

              <div v-if="recipe.video" class="mb-5">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Video
                </h5>

                <a :href="recipe.video" target="_blank">{{ recipe.video }}</a>
              </div>

              <!-- DESCRIPTION -->
              <div v-if="recipe.description" class="mb-5">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Description
                </h5>

                <p class="pre-wrap" v-html="linkify(recipe.description)"></p>
              </div>

              <!-- METHODS -->
              <div v-if="recipe.methods && recipe.methods.length" class="mb-5">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Methods
                </h5>

                <ol>
                  <li
                    v-for="(method, i) in recipe.methods"
                    :key="i"
                    class="mb-1 pre-wrap"
                  >
                    {{ method }}
                  </li>
                </ol>
              </div>

              <!-- ANALYSIS SUMMARY -->
              <div v-if="!plan?.hideEnergy">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Analysis Summary
                </h5>

                <v-card flat>
                  <v-simple-table
                    class="grey lighten-4"
                    id="nutrient-table"
                    dense
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="33%"></th>
                          <th width="33%">Quantity Per Serving</th>
                          <th width="33%">Quantity Per 100g</th>
                        </tr>
                      </thead>
                      <tbody v-if="recipe.servingValues">
                        <tr>
                          <td>Weight (g)</td>
                          <td>
                            {{
                              recipe.servingValues.weight
                                | amount
                                | numberFormat
                            }}g
                          </td>
                          <td>100.00g</td>
                        </tr>
                        <tr>
                          <td>
                            Energy
                            {{ user.energyUnit == 'cal' ? '(Cal)' : '(kJ)' }}
                          </td>
                          <td v-if="user.energyUnit == 'cal'">
                            {{
                              recipe.servingValues.energy
                                | kjToCal
                                | amount
                                | numberFormat
                            }}Cal
                          </td>
                          <td v-else>
                            {{
                              recipe.servingValues.energy
                                | amount
                                | numberFormat
                            }}kJ
                          </td>
                          <td v-if="user.energyUnit == 'cal'">
                            {{
                              recipe.servingValuesPerHundredGram.energy
                                | kjToCal
                            }}Cal
                          </td>
                          <td v-else>
                            {{ recipe.servingValuesPerHundredGram.energy }}kJ
                          </td>
                        </tr>
                        <tr>
                          <td>Protein (g)</td>
                          <td>
                            {{
                              recipe.servingValues.protein
                                | amount
                                | numberFormat
                            }}g
                          </td>
                          <td>
                            {{ recipe.servingValuesPerHundredGram.protein }}g
                          </td>
                        </tr>
                        <tr>
                          <td>Fat (g)</td>
                          <td>
                            {{
                              recipe.servingValues.fat | amount | numberFormat
                            }}g
                          </td>
                          <td>{{ recipe.servingValuesPerHundredGram.fat }}g</td>
                        </tr>
                        <tr>
                          <td>Carbohydrate (g)</td>
                          <td>
                            {{
                              recipe.servingValues.carbohydrate
                                | amount
                                | numberFormat
                            }}g
                          </td>
                          <td>
                            {{
                              recipe.servingValuesPerHundredGram.carbohydrate
                            }}g
                          </td>
                        </tr>
                        <tr>
                          <td>Sugar (g)</td>
                          <td>
                            {{
                              recipe.servingValues.sugar
                                | amount
                                | numberFormat
                            }}g
                          </td>
                          <td>
                            {{ recipe.servingValuesPerHundredGram.sugar }}g
                          </td>
                        </tr>
                        <tr>
                          <td>Fibre (g)</td>
                          <td>
                            {{
                              recipe.servingValues.fibre
                                | amount
                                | numberFormat
                            }}g
                          </td>
                          <td>
                            {{ recipe.servingValuesPerHundredGram.fibre }}g
                          </td>
                        </tr>
                        <tr>
                          <td>Sodium (mg)</td>
                          <td>
                            {{
                              recipe.servingValues.sodium
                                | amount
                                | numberFormat
                            }}mg
                          </td>
                          <td>
                            {{ recipe.servingValuesPerHundredGram.sodium }}mg
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
            </v-col>

            <v-col cols="12" md="5" lg="5" xl="5">
              <RecipeImage
                :recipe="recipe"
                @url="setImageUrl($event)"
                size="medium"
              />

              <!-- RECIPE RATING -->
              <!-- <recipe-rating :recipe="recipe" global /> -->

              <div v-if="!plan?.hideMealTags" class="mt-5">
                <div
                  class="font-weight-bold mb-2"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Meal Tags
                </div>

                <span v-for="(tag, i) in tagsConf" :key="i">
                  <v-chip
                    label
                    small
                    class="mr-1 mb-1"
                    v-if="$store.getters['tags/checkIfUnpublish'](tag)"
                    >{{ $store.getters['tags/getName'](tag) }}</v-chip
                  >
                </span>
              </div>

              <div class="mt-3" v-if="!plan?.hideNutriTags">
                <div
                  class="font-weight-bold mb-2"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Nutrition Tags
                </div>

                <span v-for="(tag, i) in recipe.nutritionTags" :key="i">
                  <v-chip
                    label
                    small
                    class="mr-1 mb-1"
                    v-if="$store.getters['nutritiontags/checkIfUnpublish'](tag)"
                    >{{
                      $store.getters['nutritiontags/tagData'](tag).name
                    }}</v-chip
                  >
                </span>
              </div>

              <!-- <v-card class="my-5" flat color="grey lighten-4">
              <v-card-text>
                <div class="d-flex align-center">
                  <v-avatar class="mr-3" size="40">
                    <UserPhoto :id="recipe.user" :size="50" photoSize="thumb" />
                  </v-avatar>
                  <div>
                    <div class="font-weight-bold">{{ $store.getters['members/getUser'](recipe.user) }} </div>
                    <div class="caption">
                      Created {{ recipe.createdAt | formatDate }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card> -->

              <!-- RATING FORM -->
              <!-- <rating-form :recipe="recipe" :rate="rate" class="mb-5" /> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DOWNLOADING DIALOG -->
    <v-dialog v-model="zipDialog" max-width="380" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <div>
            <div class="subtitle-1">Please wait...</div>
            <v-progress-linear
              :value="downloadProgress"
              color="primary"
              height="25"
            >
              <template v-slot:default>
                <strong>{{ downloadProgress }}%</strong>
              </template>
            </v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- SHOPPING LIST DIALOG -->
    <shopping-list-dialog
      @dlShoppingList="dlShoppingList()"
      @openWarningDialog="openWarningDialog()"
      ref="shoppingList"
      :meals="meals"
      :recipes="[...shoppingList]"
      :user="user"
    />

    <!-- WARNING DIALOG -->
    <warning-dialog ref="warningDialog" />
  </div>
</template>

<script>
// PLUGINS
import JSZip from 'jszip';
import { uniqBy, orderBy, upperFirst, groupBy } from 'lodash';
import { saveAs } from 'file-saver';

import { mapActions } from 'vuex';
import RecipeImage from '@/components/RecipeImage';
import ShoppingListDialog from '@/components/ShoppingListDialog';
import warningDialog from './meals/components/WarningDialog.vue';
import '../css/quill.css';

import downloadRecipe from '@/mixins/download-recipe-pdf';
// import { Carousel, Slide } from 'vue-carousel';

let zip;

export default {
  metaInfo: {
    title: 'Meal Guide Pro',
  },

  name: 'Ebook',

  /*------------------------------------------------------------------------------
   * MIXINS
   *----------------------------------------------------------------------------*/
  mixins: [downloadRecipe],

  data() {
    return {
      orderBy,
      serves: 1,
      user: null,
      goal: null,
      plan: null,
      upperFirst,
      error: null,
      recipes: [],
      meals: null,
      show: false,
      recipe: null,
      showBtn: false,
      mainMeals: null,
      shoppingList: [],
      downloading: false,
      shoppingListIds: [],
      // image: '/placeholder.svg',
      days: [
        '',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      settings: {
        dots: true,
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: 'slick-dots custom-dot-class',
      },
      slides: ['First', 'Second', 'Third', 'Fourth', 'Fifth'],
      navigateTo: [1, true],
      index: 0,
      zipDialog: false,
      image: null,
    };
  },
  components: {
    RecipeImage,
    warningDialog,
    ShoppingListDialog,
  },
  computed: {
    allRecipes: function () {
      if (!this.plan) return [];

      const recipes = this.recipes;
      let arr = [{ name: 'cover' }];

      if (!this.plan?.hideInstructionsEbook) {
        arr.push({ name: 'instruction' });
        arr.push({ name: 'table' });
      } else arr.push({ name: 'table' });

      arr = [...arr, ...recipes];

      return arr;
    },

    tagsConf: function () {
      if (this.recipe && this.recipe.tags) {
        return this.recipe.tags.filter((tag) => {
          return this.$store.getters['tags/getName'](tag);
        });
      } else return [];
    },
    mdUp: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    smDown: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },

    groupedRecipes: function () {
      return groupBy(this.recipes, 'meal.time');
    },
    headers: function () {
      let arr = Object.keys(this.groupedRecipes);

      return arr.sort((a, b) => {
        let order = 'brealuncdinndesssnac';
        return order.indexOf(a.slice(0, 4)) - order.indexOf(b.slice(0, 4));
      });
    },
    downloadProgress: function () {
      if (!this.meals.length || !this.recipes.length) return null;

      var meals = this.meals?.filter(
        (m) =>
          m.recipe !== 'no meal' && this.recipes.find((r) => r.id == m.recipe)
      ).length;
      var progress = this.index / meals;
      return Math.round(progress * 100);
    },
  },
  methods: {
    ...mapActions('mealplans', ['getPlanToDownload']),
    setImageUrl(e) {
      this.image = e;
    },

    setRecipe(recipe) {
      this.recipe = recipe;
      this.show = true;
    },

    linkify(text) {
      // Regular expression to match URLs
      const urlRegex = /(\b(?:https?|ftp):\/\/|www\.)\S+\b/gi;

      // Replace URLs with hyperlink tags
      return text.replace(urlRegex, function (url) {
        // Add http:// prefix if the URL doesn't have one
        if (!url.match(/^https?:\/\//i)) {
          url = 'http://' + url;
        }
        return '<a href="' + url + '">' + url + '</a>';
      });
    },

    addAllToShoppingList() {
      this.removeAllFromShoppingList();
      this.recipes.forEach((r) => this.addToShoppingList(r));
    },

    removeAllFromShoppingList() {
      this.recipes.forEach((r) => this.removeFromShoppingList(r.id));
    },

    addToShoppingList(recipe) {
      this.shoppingList.push(recipe);
      this.shoppingListIds.push(recipe.id);
    },

    removeFromShoppingList(id) {
      this.shoppingList = this.shoppingList.filter((s) => s.id !== id);
      this.shoppingListIds = this.shoppingListIds.filter((s) => s !== id);
    },
    showShoppingListDialog() {
      this.$refs.shoppingList.showDialog();
    },
    openWarningDialog() {
      this.$refs.warningDialog.openDialog();
    },

    startDownload() {
      this.downloading = true;

      Promise.resolve(this.downloadPDF()).then(() => {
        this.downloading = false;
      });
    },

    addFixedDecimals(val) {
      if (val % 1 != 0) return this.$options.filters.amount(val);

      return val;
    },

    goTo(no) {
      for (let i = 1; i <= no; i++) {
        this.$refs.carousel.next();
      }
    },

    goBack(no) {
      let limit = 3;

      if (this.plan?.hideInstructionsEbook) limit = 2;

      for (let i = no; i >= limit; i--) {
        this.$refs.carousel.prev();
      }
    },

    getIndexNo(id) {
      return this.recipes.findIndex((recipe) => recipe.id == id);
    },

    parseMealTime(time) {
      const notSnack = ['breakfast', 'lunch', 'dinner', 'desert'];

      if (!notSnack.includes(time)) return this.getAlternateWordSnack(time);

      // if (time == 'desert') return 'dessert';

      return this.getAlternateWord(time);
    },

    getAlternateWordSnack(val) {
      if (!this.plan?.alternateWords) return 'snack';

      if (
        Object.prototype.hasOwnProperty.call(this.plan?.alternateWords, val)
      ) {
        return this.plan.alternateWords[val];
      } else return 'snack';
    },

    getAlternateWord(val) {
      if (!this.plan?.alternateWords) return val == 'desert' ? 'dessert' : val;

      if (
        Object.prototype.hasOwnProperty.call(this.plan?.alternateWords, val)
      ) {
        return this.plan.alternateWords[val];
      } else return val == 'desert' ? 'dessert' : val;
    },

    getMealTimes(recipes, meals) {
      let confRecipes = [];
      const order = {
        breakfast: 1,
        lunch: 2,
        dinner: 3,
        desert: 4,
        snack1: 5,
        snack2: 5,
        snack3: 5,
        snack4: 5,
      };

      confRecipes = recipes
        .filter((recipe) => meals.find((meal) => recipe.id === meal?.recipe))
        .map((recipe) => {
          let mealTime = meals.find((meal) => recipe.id === meal?.recipe);

          return {
            ...recipe,
            meal: {
              time: this.parseMealTime(mealTime?.meal),
              order: order[mealTime?.meal],
            },
          };
        });

      return confRecipes;
    },

    getRecipeImg(recipe) {
      let img = 'none';

      if (recipe?.mediumPhotoUrl) img = recipe.mediumPhotoUrl;
      else if (recipe?.OriginalPhotoUrl) img = recipe.OriginalPhotoUrl;
      else if (recipe?.thumbPhotoUrl) img = recipe.thumbPhotoUrl;
      else
        img =
          'https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fno_image.png?alt=media&token=9cd541b8-ce97-4b99-b8d7-905b5e198b5f';

      return img;
    },
    startDownloadRecipes(index) {
      this.index = index;
      this.downloadRecipes(index);
      zip = new JSZip();
    },
    downloadRecipes(index) {
      console.log('start');
      var meals = this.meals.filter((m) => m.recipe !== 'no meal');
      var meal = meals[index];
      this.zipDialog = true;

      this.recipe = this.recipes.find((r) => r.id == meal.recipe);

      if (!this.recipe) {
        console.log('call');
        this.index = index + 1;
        this.downloadRecipes(this.index);
      } else {
        console.log('download');
        this.image = this.recipe.mediumPhotoUrl || this.recipe.OriginalPhotoUrl;

        Promise.resolve(this.downloadPDF(true))
          .then((doc) => {
            console.log(doc);
            zip.file(`${this.recipe.name}.pdf`, doc.output('blob'));
            console.log(this.index + 1 == meals.length);

            if (this.index + 1 == meals.length) {
              zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, 'recipes.zip');
                this.zipDialog = false;
              });
            } else {
              console.log('call again');
              this.index = index + 1;
              this.downloadRecipes(this.index);
            }
          })
          .catch(() => {
            this.downloadRecipes(this.index);
          });
      }
    },
  },

  mounted() {
    if (!this.$store.state.tags.status.firstLoad)
      this.$store.dispatch('tags/getTags');

    this.$store.dispatch('nutritiontags/getTags');
    this.$store.dispatch('foods/getCategories');

    console.log(this.$route.query.i);

    this.getPlanToDownload(this.$route.query.i).then((res) => {
      const { user, mainMeals, meals, goal, recipes } = res.linkDetails;

      Promise.resolve(
        this.$store.dispatch('user/getUserDataFromId', user.id)
      ).then((data) => {
        this.plan = res;
        this.user = data;
        this.mainMeals = mainMeals;
        this.meals = uniqBy(meals, (meal) => meal.recipe);
        this.goal = goal;
        // this.recipes = orderBy(uniqBy(this.getMealTimes(recipes, meals), (recipe) => recipe.name),'meal.time' , 'asc');
        this.recipes = orderBy(
          this.getMealTimes(
            uniqBy(recipes, (recipe) => recipe.name),
            meals
          )
        );

        this.plan = res;

        this.recipes.forEach((recipe) => {
          this.$store.dispatch('recipes/getRecipeIngredients', recipe);
        });
      });
    });
  },
};
</script>
<style lang="scss" scoped>
$primary: #97c81f;
$black: rgba(0, 0, 0, 0.87);

* {
  box-sizing: border-box;
}

.put_center {
  display: grid;
  place-items: center;
}

.custom-card {
  position: relative;
}

.bg {
  height: 5%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.bg-text {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 0 10px;
  color: #fff;
}

.social-medias {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 0 10px;
  color: #fff;
}

.sample-text {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 50px;
  font-weight: bold;
  padding: 0 10px;
  color: #fff;
}

.shoppingcart {
  position: absolute;
  right: 20px;
  padding: 0 10px;
  color: #fff;
}

.table-content-item {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.stamp-container {
  height: 100px;
}

.stamp-seal {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

$badge-font-size: 8px;
</style>
